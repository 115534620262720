export function formatBytes(bytes: number): string {
    if (bytes < (1 << 20)) {
        return (bytes / (1 << 10)).toFixed(1) + "KB"
    } else {
        return (bytes / (1 << 20)).toFixed(1) + "MB"
    }
}

export function mapPaperType(type: string): string {
    switch (type) {
        case 'ms':
            return 'Mark Scheme'
        case 'qp':
            return 'Question Paper'
        case 'rp':
            return 'Question Paper'
        case 'er':
            return 'Examiner Report'
        case 'gt':
            return 'Grade Thresholds'
        case 'ir':
            return 'Information Report'
        case 'tn':
            return 'Teacher\'s Notes'
        case 'sf':
            return 'Audio'
        case 'qr':
            return 'Transcript'
        default:
            return "Other"
    }
}

export function getPaperMonth(name: string): string {
    switch (name[5]) {
        case 's':
            return 'May/June'
        case 'w':
            return 'October/November'
        case 'm':
            return 'February/March'
        case 'j':
            return 'July'
        default:
            return ''
    }
}

export function convertObj2Form(obj) {
    let form_data = new FormData();
    for (var key in obj) {
        form_data.append(key, obj[key]);
    }
    return form_data
}

export const USERNAME_REGEX = /^(?=.{5,20}$)(?!_)(?!.*_{2})[a-zA-Z0-9_]+[a-zA-Z0-9]$/;
export const EMAIL_REGEX = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/
export const PASSWORD_REGEX = /^(?=.{8,20}$).*$/;
