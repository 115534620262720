import { useState, useContext, useCallback } from 'react';

import { useRouter } from 'next/router'
import Link from 'next/link'

import { useIntl } from 'react-intl'

import { Form, Toast, Row, Button, Typography } from '@douyinfe/semi-ui';
import { IconMail, IconKey } from '@douyinfe/semi-icons';

import { UserContext } from 'contexts/userContext';
import { userLogin, getUserSelfInfo } from 'apis/user'
import { convertObj2Form } from 'utils/utils';
import InputPassHideToggle from 'components/common/inputPassHideToggle';

export default function LoginForm(): JSX.Element {
    const router = useRouter()
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const { Text } = Typography;
    const { localLogIn } = useContext(UserContext)
    const [passVisible, setPassVisible] = useState(false)

    const togglePassVisible = useCallback(() => {
        setPassVisible(prev => !prev)
    }, [])

    return (
        <Form
            onSubmit={(values) => {
                setLoading(true)
                setTimeout(() => {
                    userLogin(convertObj2Form(values)).then(() => {
                        getUserSelfInfo().then((res) => {
                            Toast.success({ content: intl.formatMessage({ defaultMessage: "Logged in successfully", id: 'nsaJRn' }) })
                            localLogIn(res.data)
                            router.push("/")
                        })
                    }).catch(err => {
                        Toast.error({ content: intl.formatMessage({ defaultMessage: "Failed to log in", id: 'NRMg1O' }) })
                    }).finally(() => {
                        setLoading(false)
                    })
                }, 1000);

            }}
        >
            <Form.Section text={intl.formatMessage({ defaultMessage: 'Log in', id: 'odXlk8' })}>
                <Row>
                    <Form.Input
                        type="text"
                        disabled={loading}
                        prefix={<IconMail />}
                        label={intl.formatMessage({ defaultMessage: 'Username/Email', id: '0wBz4H' })}
                        field='identifier'
                        placeholder={intl.formatMessage({ defaultMessage: 'Please enter ', id: '1nW1Bx' }) + intl.formatMessage({ defaultMessage: 'username or email', id: 'LQ69B7' })}
                    />
                </Row>
                <Row>
                    <Form.Input
                        type={passVisible ? 'text' : 'password'}
                        disabled={loading}
                        prefix={<IconKey />}
                        suffix={<InputPassHideToggle toggle={passVisible} onClick={togglePassVisible} />}
                        label={intl.formatMessage({ defaultMessage: 'Password', id: '5sg7KC' })}
                        field='password'
                        placeholder={intl.formatMessage({ defaultMessage: 'Please enter ', id: '1nW1Bx' }) + intl.formatMessage({ defaultMessage: 'password', id: 'sQnzu7' })}
                    />
                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20 }}>
                    <Button loading={loading} block theme='solid' type='primary' htmlType='submit' style={{ height: 40 }}>{intl.formatMessage({ defaultMessage: 'Submit', id: 'wSZR47' })}</Button>
                </Row>
                <Row>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <div>
                            <Link href="signup">
                                <a><Text link >{intl.formatMessage({ defaultMessage: 'Create new account', id: 'phTfBA' })}</Text></a>
                            </Link>
                        </div>
                        <div>
                            <Link href="recover-password">
                                <a><Text link >{intl.formatMessage({ defaultMessage: 'Forgot password?', id: 'V/JHlm' })}</Text></a>
                            </Link>
                        </div>
                    </div>
                </Row>
            </Form.Section>
        </Form>
    )
}