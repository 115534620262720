import type { NextPage } from 'next'

import { Row, Col, Breadcrumb, Typography, Card } from '@douyinfe/semi-ui'

import LoginForm from 'components/account/loginForm'
import GlobalLayout from 'components/globalLayout'

const LoginPage: NextPage = () => {
    return (
        <GlobalLayout title='Login - MFPapers'>
            <Row style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 600,
                height: 'calc(100vh - 145px)',
                backgroundColor: '#0093E9',
                backgroundImage: "radial-gradient( circle 588px at 31.7% 40.2%,  rgba(225,200,239,1) 21.4%, rgba(163,225,233,1) 57.1% )",
                justifyContent: 'center'
            }}>
                <Col md={24} lg={{ span: 8, offset: 8 }}>
                    <Card
                        style={{
                            margin: '0 10px',
                            cursor: 'default'
                        }}
                        shadows='always'
                    >
                        <LoginForm />
                    </Card>
                </Col>
            </Row>
        </GlobalLayout>
    )
}

export default LoginPage