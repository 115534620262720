import { IconEyeOpened, IconEyeClosedSolid } from "@douyinfe/semi-icons"


function InputPassHideToggle({ toggle, onClick }: { toggle: boolean, onClick: () => void }) {
    return (
        <div onClick={onClick} style={{
            cursor: 'pointer',
            display: 'inherit',
            marginRight: 5
        }}>
            {toggle ? <IconEyeClosedSolid /> : <IconEyeOpened />}
        </div>

    )
}

export default InputPassHideToggle